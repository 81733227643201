<template>
  <b-overlay :show="!ready" variant="dark" opacity="0.85" blur="2px" rounded="sm">
    <section class="invoice-preview-wrapper">
      <b-row class="invoice-preview d-flex justify-content-center">
        <!-- Col: Left (Invoice Container) -->
        <b-col cols="12" md="10" xl="9" v-if="ready">
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div
                class="
                  d-flex
                  justify-content-between
                  flex-md-row flex-column
                  invoice-spacing
                  mt-0
                "
              >
                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <h3 class="text-primary invoice-logo">
                      {{ transactionDetail.master_company_json.company_trade_name }}
                    </h3>
                  </div>
                  <p class="card-text mb-0">
                    {{ transactionDetail.master_company_json.tax_number }} -
                    {{ transactionDetail.master_company_json.tax_office_name }}
                  </p>
                  <p class="card-text mb-25">
                    {{ transactionDetail.master_company_json.address_line1 }}
                  </p>
                  <p class="card-text mb-25">
                    {{ transactionDetail.master_company_json.post_code }} - {{ transactionDetail.master_company_json.town_name }} /
                    {{ transactionDetail.master_company_json.city_name }}
                    {{ transactionDetail.master_company_json.country_name }}
                  </p>
                  <p class="card-text mb-0">
                    {{ phoneFormat(transactionDetail.master_company_json.phone) }}
                  </p>
                  <p class="card-text mb-0">
                    {{ transactionDetail.master_company_json.e_mail }}
                  </p>
                </div>

                <!-- Header: Right Content -->
                <div class="mt-md-0 mt-2">
                  <h4 class="invoice-title">
                    {{ $t('transaction.no') }}
                    <span class="invoice-number">{{ `#${transactionId}` }}</span>
                  </h4>
                  <div v-if="transactionDetail.orderDetail" class="invoice-date-wrapper">
                    <p class="invoice-date-title">{{ $t('order.no') }}:</p>
                    <router-link :to="{ name: '/Shop/OrderDetail', params: { id: transactionDetail.orderDetail.order_m_id } }" target="_blank" class="invoice-date">
                      {{ `#${transactionDetail.orderDetail.order_no}` }}
                    </router-link>
                  </div>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">
                      {{ $t('transaction.date') }}
                    </p>
                    <p class="invoice-date">{{ transactionDate }}</p>
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Invoice Client & Payment Details -->
            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <!-- Col: Invoice To -->
                <b-col cols="12" xl="6" class="p-0">
                  <h6 class="mb-2">
                    {{ $t('transaction.payment_info') }}
                  </h6>
                  <div class="logo-wrapper">
                    <h3 class="text-primary invoice-logo">
                      {{ transactionDetail.user_name_surname }}
                    </h3>
                  </div>
                  <p class="card-text mb-25">
                    {{ transactionDetail.company_json.company_trade_name }}
                  </p>
                  <p class="card-text mb-0">
                    {{ transactionDetail.company_json.tax_number }} -
                    {{ transactionDetail.company_json.tax_office_name }}
                  </p>
                  <p class="card-text mb-25">
                    {{ transactionDetail.company_json.address_line1 }}
                  </p>
                  <p class="card-text mb-25">
                    {{ transactionDetail.company_json.post_code }} - {{ transactionDetail.company_json.town_name }} /
                    {{ transactionDetail.company_json.city_name }}
                    {{ transactionDetail.company_json.country_name }}
                  </p>
                  <p class="card-text mb-0">
                    {{ phoneFormat(transactionDetail.company_json.phone) }}
                  </p>
                  <p class="card-text mb-0">
                    {{ transactionDetail.company_json.e_mail }}
                  </p>
                </b-col>

                <!-- Col: Payment Details -->
                <b-col xl="6" cols="12" class="p-0 mt-xl-0 mt-2 d-flex justify-content-end pr-2">
                  <div>
                    <h6 class="mb-2">
                      {{ $t('transaction.payment_detail') }}
                    </h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">
                            {{ $t('transaction.total_amount') }}
                          </td>
                          <td>
                            <span class="font-weight-bold">
                              {{ showPrice(transactionDetail.total_amount) }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            {{ $t('transaction.bank_name') }}
                          </td>
                          <td>{{ transactionDetail.bank_id ? transactionDetail.bank_id : $t('payment.table.undefined') }}</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            {{ $t('transaction.ip_address') }}
                          </td>
                          <td>{{ transactionDetail.ip_address }}</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            {{ $t('transaction.type') }}
                          </td>
                          <td>{{ transactionDetail.transaction_type_name }}</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            {{ $t('transaction.state') }}
                          </td>
                          <td>
                            {{ transactionDetail.transaction_state_name }}
                          </td>
                        </tr>
                        <tr v-if="transactionDetail.erp_master_id">
                          <td class="pr-1">ERP ID:</td>
                          <td>
                            {{ transactionDetail.erp_master_id }}
                          </td>
                        </tr>
                        <tr>
                          <th colspan="2">{{ $t('transaction.currency_info') }}</th>
                        </tr>
                        <tr v-for="(item, index) in transactionDetail.currencyList" :key="`transaction_detail_currency_${index}`">
                          <td>{{ item.code }}:</td>
                          <td>{{ showCurrenyRate(item.ask) }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Invoice Description: Table -->
            <b-table-lite responsive :items="transactionDetail.payment_detail" :fields="paymentDetailFields" class="detail-table">
              <template #cell(com)="data">
                {{ showCommissionRate(data.value) }}
              </template>
              <template #cell(total)="data">
                {{ showPrice(data.value) }}
              </template>
            </b-table-lite>

            <!-- Invoice Description: Total -->
            <b-card-body>
              <b-row>
                <!-- Col: Sales Persion -->
                <b-col cols="12" md="6" class="mt-md-0 mt-3" order="2" order-md="1">
                  <b-card-text class="mb-0">
                    <span class="font-weight-bold">
                      {{ $t('transaction.bank_response') }}
                    </span>
                    <span class="ml-75">
                      {{ transactionDetail.detail_desc }}
                    </span>
                  </b-card-text>
                </b-col>

                <!-- Col: Total -->
                <b-col cols="12" md="6" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2" style="padding:0!important;">
                  <div class="d-flex justify-content-end">
                    <table style="width: 35rem;">
                      <thead>
                        <tr>
                          <th class="w-100"></th>
                          <th class="text-center">
                            <h5 class="font-weight-bolder">USD</h5>
                          </th>
                          <th class="text-center">
                            <h5 class="font-weight-bolder">TRY</h5>
                          </th>
                        </tr>
                      </thead>
                      <tbody :set="(amountDetail = getAmountDetail())">
                        <tr>
                          <th>
                            <h5 class="font-weight-bolder">{{ $t('transaction.amount') }}</h5>
                          </th>
                          <td class="px-2 text-right text-nowrap font-weight-bolder">{{ amountDetail.TOTAL_USD_SHOW }}</td>
                          <td class="px-2 text-right text-nowrap font-weight-bolder text-warning">{{ amountDetail.TOTAL_TRY_SHOW }}</td>
                        </tr>
                        <tr class="border-bottom" style="border-width: 2px!important;">
                          <th>
                            <h5 class="font-weight-bolder">{{ $t('transaction.commission') }}</h5>
                          </th>
                          <td class="px-2 text-right text-nowrap font-weight-bolder">{{ amountDetail.TOTAL_USD_C_SHOW }}</td>
                          <td class="px-2 text-right text-nowrap font-weight-bolder text-warning">{{ amountDetail.TOTAL_TRY_C_SHOW }}</td>
                        </tr>
                        <tr>
                          <th>
                            <h5 class="font-weight-bolder">{{ $t('transaction.total_amount') }}</h5>
                          </th>
                          <td class=" px-2 text-right text-nowrap font-weight-bolder">{{ amountDetail.TOTAL_USD_AWC_SHOW }}</td>
                          <td class=" px-2 text-right text-nowrap font-weight-bolder text-warning">{{ amountDetail.TOTAL_TRY_AWC_SHOW }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">
                {{ $t('transaction.note') }}
              </span>
              <span>{{ transactionDetail.master_desc }}</span>
            </b-card-body>
          </b-card>
          <div class="d-flex justify-content-between print-button-div">
            <div>
              <b-button
                variant="danger"
                class="d-flex justify-content-center align-items-center"
                @click="cancelPayment()"
                v-if="saveState && dayDiff === 0 && $can('manage', '/Payment/Cancel') && transactionDetail.transaction_type === 0"
                :disabled="buttonDisable"
              >
                {{ $t('transaction.cancel_button_text') }}
                <b-spinner class="ml-1" small v-show="buttonDisable" />
              </b-button>
              <b-button
                variant="danger"
                class="d-flex justify-content-center align-items-center"
                @click="returnPayment()"
                v-if="saveState && dayDiff > 0 && $can('manage', '/Payment/Return') && transactionDetail.transaction_type === 0"
                :disabled="buttonDisable"
              >
                {{ $t('transaction.return_button_text') }}
                <b-spinner class="ml-1" small v-show="buttonDisable" />
              </b-button>
            </div>
            <div class="d-flex">
              <b-button
                variant="warning"
                class="
                  mb-75
                  mr-1
                  d-flex
                  justify-content-center
                  align-items-center
                "
                @click="openModal()"
                v-if="saveState && $can('manage', '/Erp/SavePaymentErp') && transactionDetail.erp_master_id === null"
                :disabled="buttonDisable"
              >
                {{ $t('transaction.confirm_button_text') }}
                <b-spinner class="ml-1" small v-show="buttonDisable" />
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="primary" class="mb-75" @click="printInvoice">
                {{ $t('transaction.print') }}
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </section>
    <b-modal ref="modal" hide-footer centered no-close-on-backdrop size="md" :title="$t('transaction.swal.confirm_title')" v-if="saveState">
      <b-form-group :label="`${$t('transaction.swal.confirm_note')}`" label-for="confirm-note">
        <b-form-textarea id="confirm-note" :placeholder="$t('transaction.swal.confirm_note_placeholder')" v-model="paymentNote" rows="2" no-resize />
      </b-form-group>
      <b-card-text class="text-center my-1" v-html="$t('transaction.swal.html')" />
      <div class="d-flex justify-content-between">
        <b-button variant="danger" @click="closeModal()">
          {{ $t('transaction.swal.give_up') }}
        </b-button>
        <b-button variant="success" class="d-flex justify-content-center align-items-center" @click="confirmPayment()" :disabled="buttonDisable">
          {{ $t('transaction.swal.confirm') }}
          <b-spinner class="ml-1" small v-show="buttonDisable" />
        </b-button>
      </div>
    </b-modal>
  </b-overlay>
</template>

<script>
import { BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BModal, BFormGroup, BFormTextarea, BSpinner } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import { CANCEL_PAYMENT, CONFIRM_PAYMENT, GET_TRANSACTION_DETAIL, RETURN_PAYMENT } from '@/store/services/payment-service'
import { mapGetters } from 'vuex'
import { convertTL, priceFormat } from '@/assets/js/functions/currency-functions'

export default {
  name: 'payment-transaction-detail',
  directives: {
    Ripple,
    'b-toggle': VBToggle
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BOverlay,
    BModal,
    BFormGroup,
    BFormTextarea,
    BSpinner
  },
  data() {
    return {
      ready: false,
      transactionDetail: null,
      transactionId: this.$route.params.id,
      paymentNote: '',
      buttonDisable: false
    }
  },
  created() {
    this.getData()
  },
  computed: {
    ...mapGetters(['getLocale', 'currentUser']),
    transactionDate() {
      return moment(this.transactionDetail?.transaction_date).format('DD.MM.YYYY HH:mm:ss')
    },
    paymentDetailFields() {
      return [
        {
          key: 'cc_holder',
          label: this.$t('transaction.credit_card_holder'),
          tdClass: 'text-nowrap text-uppercase'
        },
        {
          key: 'cc_no',
          label: this.$t('transaction.credit_card'),
          tdClass: 'text-nowrap'
        },
        {
          key: 'ins',
          label: this.$t('transaction.installment'),
          tdClass: 'text-right',
          thClass: 'text-right'
        },
        {
          key: 'e_ins',
          label: this.$t('transaction.extra_installment'),
          tdClass: 'text-right',
          thClass: 'text-right'
        },
        {
          key: 'com',
          label: this.$t('transaction.commission'),
          tdClass: 'text-right',
          thClass: 'text-right'
        },
        {
          key: 'total',
          label: this.$t('transaction.total'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right'
        }
      ]
    },
    saveState() {
      return this.currentUser?.company?.master_company && this.transactionDetail?.transaction_state === 1
    },
    dayDiff() {
      let transaction_date = moment(this.transactionDetail?.transaction_date)
      let today = moment()
      let diff = today.diff(transaction_date, 'days')
      return diff
    }
  },
  methods: {
    getData() {
      this.ready = false
      this.$store.dispatch(GET_TRANSACTION_DETAIL, this.transactionId).then(response => {
        this.ready = true
        this.transactionDetail = response
      })
    },
    printInvoice() {
      window.print()
    },
    showPrice(price) {
      return priceFormat(price, this.getLocale, 'TRY')
    },
    showCommissionRate(rate) {
      return (
        new Intl.NumberFormat(this.getLocale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        }).format(rate) + ' %'
      )
    },
    phoneFormat(phone) {
      return `+90 ${phone.slice(0, 3)} ${phone.slice(3, 6)} 
      ${phone.slice(6, 8)} ${phone.slice(8, 10)}`
    },
    openModal() {
      this.$refs.modal.show()
      this.paymentNote = ''
    },
    closeModal() {
      this.$refs.modal.hide()
      this.paymentNote = ''
    },
    confirmPayment() {
      this.buttonDisable = true
      this.$store
        .dispatch(CONFIRM_PAYMENT, {
          transaction_d_id: this.transactionDetail.transaction_d_id,
          Note: this.paymentNote
        })
        .then(() => {
          this.getData()
        })
        .catch(() => {})
        .finally(() => {
          this.closeModal()
          this.buttonDisable = false
        })
    },
    cancelPayment() {
      this.buttonDisable = true
      this.$swal({
        title: this.$t('transaction.swal.cancel_title'),
        html: this.$t('transaction.swal.html'),
        icon: 'warning',
        customClass: {
          denyButton: 'btn btn-secondary',
          confirmButton: 'btn btn-danger mr-2'
        },
        showDenyButton: true,
        denyButtonText: this.$t('transaction.swal.give_up'),
        confirmButtonText: this.$t('transaction.swal.cancel'),
        buttonsStyling: false
      }).then(res => {
        if (res.value) {
          this.$store
            .dispatch(CANCEL_PAYMENT, this.transactionDetail.transaction_id)
            .then(transaction_id => {
              this.transactionId = transaction_id
              this.getData()
            })
            .catch(() => {})
            .finally(() => {
              this.buttonDisable = false
            })
        }
      })
    },
    returnPayment() {
      this.buttonDisable = true
      this.$swal({
        title: this.$t('transaction.swal.return_title'),
        html: this.$t('transaction.swal.html'),
        icon: 'warning',
        customClass: {
          denyButton: 'btn btn-secondary',
          confirmButton: 'btn btn-danger mr-2'
        },
        showDenyButton: true,
        denyButtonText: this.$t('transaction.swal.give_up'),
        confirmButtonText: this.$t('transaction.swal.return'),
        buttonsStyling: false
      }).then(res => {
        if (res.value) {
          this.$store
            .dispatch(RETURN_PAYMENT, this.transactionDetail.transaction_id)
            .then(transaction_id => {
              this.transactionId = transaction_id
              this.getData()
            })
            .catch(() => {})
            .finally(() => {
              this.buttonDisable = false
            })
        }
      })
    },
    getAmountDetail() {
      let rate = this.transactionDetail?.currencyList?.find(x => x.code === 'USD')?.ask ?? 1
      let TOTAL_TRY = this.transactionDetail.amount
      let TOTAL_TRY_AWC = this.transactionDetail.total_amount
      let TOTAL_TRY_C = TOTAL_TRY_AWC - TOTAL_TRY

      let TOTAL_USD = TOTAL_TRY / rate
      let TOTAL_USD_AWC = TOTAL_TRY_AWC / rate
      let TOTAL_USD_C = TOTAL_TRY_C / rate

      let TOTAL_TRY_SHOW = priceFormat(TOTAL_TRY, this.getLocale, 'TRY')
      let TOTAL_TRY_AWC_SHOW = priceFormat(TOTAL_TRY_AWC, this.getLocale, 'TRY')
      let TOTAL_TRY_C_SHOW = priceFormat(TOTAL_TRY_C, this.getLocale, 'TRY')

      let TOTAL_USD_SHOW = priceFormat(TOTAL_USD, this.getLocale, 'USD')
      let TOTAL_USD_AWC_SHOW = priceFormat(TOTAL_USD_AWC, this.getLocale, 'USD')
      let TOTAL_USD_C_SHOW = priceFormat(TOTAL_USD_C, this.getLocale, 'USD')

      return {
        TOTAL_TRY_SHOW,
        TOTAL_TRY_AWC_SHOW,
        TOTAL_TRY_C_SHOW,

        TOTAL_USD_SHOW,
        TOTAL_USD_AWC_SHOW,
        TOTAL_USD_C_SHOW
      }
    },
    showCurrenyRate(rate) {
      return new Intl.NumberFormat(this.getLocale, {
        maximumFractionDigits: 4,
        minimumFractionDigits: 4
      }).format(rate)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
.invoice-total-wrapper {
  margin-right: 96px;
}
.invoice-total-item {
  width: 250px;
}
.invoice-logo {
  max-width: 500px;
  font-size: 1.25rem !important;
  margin: 0 !important;
}
.invoice-date-title {
  width: auto !important;
}
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none !important;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  .print-button-div {
    display: none !important;
  }
  .breadcrumbs-top {
    display: none;
  }
}
.detail-table {
  table {
    thead,
    tbody {
      tr {
        td,
        th {
          padding: 0.25rem !important;
          vertical-align: middle;
        }
      }
    }
    caption {
      table {
        thead,
        tbody {
          tr {
            td,
            th {
              padding: 0.25rem 1rem !important;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}
</style>
